.rowsHeader {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-bottom: 8px;

  .pageName {
    width: 25%;
  }

  .metrics {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .valueTitle {
      display: inline-block;
      text-align: center;
      width: calc(100% / 5);
    }
  }
}
