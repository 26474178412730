.wrapperTable {
  .sectionPadding {
    padding: 0 20px;
  }

  .sectionWrapper {
    width: 100%;
    background: white;
    border-radius: 4px;
    border-collapse: collapse;
    margin-bottom: 20px;

    .listWrapper {
      width: 100%;

      .listItem {
        padding: 0 20px;

        > div {
          border: none !important;
          border-bottom: 1px solid #ebecf0 !important;
        }

        &:last-of-type {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
}
