@import '~@kontentino/ui/dist/scss/colors';
@import '~@kontentino/ui/dist/scss/shadows';
@import 'src/styles/scrollbar';

.dropdownWrapper {
  @include box-shadow-large;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  background: $white;
  border-radius: 8px;
  z-index: 2;
  overflow: hidden;
}

.dropdownWrapperInner {
  display: flex;
  height: 490px;
}

.filterOptionsWrapper {
  width: 168px;
  padding: 8px;
  background: $gray05;
  display: flex;
  flex-direction: column;

  .filterOptionsWrapperList {
    @include styled-scrollbar;
    overflow: auto;
    height: auto;
  }

  .filterOptionsFooter {
    margin-top: auto;
  }
}

.optionsWrapper {
  width: 360px;

  .optionsHeader {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 8px 16px;
  }

  .optionsList {
    @include styled-scrollbar;
    overflow: auto;
    height: calc(100% - 56px);
    padding: 0 16px;
  }
}
