.mainSections {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .mainRoute {
    height: max-content;
    width: 100%;
    padding: 16px 24px 116px;
  }
}
