@import '~@kontentino/ui/dist/scss/colors';

.filterOptionButton {
  padding: 8px;
  user-select: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  background: transparent;
  display: flex;
  align-items: center;
  align-content: stretch;

  & + .filterOptionButton {
    margin-top: 8px;
  }

  &:hover {
    background: $gray20;
  }

  .label {
    margin-left: 10px;
    line-height: 16px;
    font-weight: 500;
    font-size: 14px;
    color: $gray100;
  }
}

.filterOptionButtonActive {
  background: $gray20;
}
