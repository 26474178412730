@import '~@kontentino/ui/dist/scss/colors';

@keyframes popup-show-animation {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes popup-hide-animation {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

.popup-show {
  animation: 300ms ease 0s 1 normal none running popup-show-animation;
}

.popup-hide {
  animation: 300ms ease 0s 1 normal none running popup-hide-animation;
}

[data-navigation-expanded='true'] .swal2-container.swal2-container {
  padding-left: 240px;
}

.swal2-container.swal2-container {
  padding-left: 64px;

  &.swal2-backdrop-show {
    @apply tw-bg-grayscale-180 tw-bg-opacity-10;
  }

  .swal2-modal {
    @apply tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-xl;
    min-width: 400px;
    max-width: 60%;
    width: auto;
  }

  .swal2-title {
    @apply tw-m-0 tw-mb-1 tw-p-0 tw-text-left tw-text-lg tw-font-semibold tw-text-grayscale-180;
  }

  .swal2-html-container {
    @apply tw-m-0 tw-text-left tw-text-md tw-font-regular;
  }

  .swal2-close {
    @apply tw-pointer-events-none tw-absolute tw-right-3 tw-top-3 tw-m-0 tw-h-auto tw-w-auto tw-overflow-visible tw-shadow-none;

    & > button {
      @apply tw-pointer-events-auto;
    }
  }

  .swal2-actions {
    @apply tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-3;

    button.swal2-styled {
      @apply tw-m-0 tw-inline-flex tw-h-10 tw-items-center tw-justify-center tw-gap-1 tw-rounded tw-px-3 tw-text-md tw-font-medium tw-transition-colors;
      @apply disabled:tw-cursor-not-allowed;

      &:focus {
        @apply tw-shadow-none;
      }

      &.swal2-confirm {
        @apply tw-bg-primary-100  tw-text-white;
        @apply hover:tw-bg-primary-120;
        @apply disabled:tw-bg-grayscale-10 disabled:tw-text-grayscale-50;
      }

      &.swal2-cancel {
        @apply tw-bg-grayscale-10 tw-text-grayscale-180 hover:tw-bg-grayscale-100;
        @apply hover:tw-text-white;
        @apply disabled:tw-bg-grayscale-10 disabled:tw-text-grayscale-50;
      }
    }
  }
}
