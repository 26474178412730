@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Tobias Bold';
  src: url('../../node_modules/@kontentino/ui/src/fonts/Tobias-Bold.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}
