.Toastify__toast-container--bottom-left {
  bottom: 16px;
  left: 16px;
  width: 400px;

  .Toastify__toast--default {
    margin: 0px 0px 8px 0px;
  }
}

.Toastify__toast-container--top-right {
  top: 80px;
  right: 12px;
  width: 280px;
}

.Toastify__toast {
  @apply tw-font-sans;

  &.toast--error {
    @apply tw-border tw-border-grayscale-20 tw-bg-white tw-shadow-lg;
    height: auto;
    padding: 0;
    padding-right: 16px;
    display: flex;
    align-items: flex-start;

    .Toastify__toast-body {
      padding: 0;
      margin-right: 0;
    }

    .Toastify__close-button--custom {
      @apply tw-text-grayscale-180;
      margin-left: 0px;
      margin-top: 12px;
    }
  }
}
