@import '~@kontentino/ui/dist/scss/colors';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.ps__thumb-y {
  background-color: transparentize($gray80, 0.3);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: transparentize($gray80, 0.1);
  width: 8px;
}

.ps__thumb-x {
  background-color: transparentize($gray80, 0.3);
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: transparentize($gray80, 0.1);
  width: 8px;
}

.ps__rail-y {
  width: 12px;
}

.ps__rail-x {
  width: 12px;
}
