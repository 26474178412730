@import '~@kontentino/ui/dist/scss/colors';

.linearToggleDivider {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 16px 0 0;

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    height: 1px;
    background-color: $gray20;
    z-index: -1;
  }

  button {
    position: relative;
    border-radius: 50%;

    &:before {
      position: absolute;
      display: block;
      content: '';
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      border: 4px solid $gray05;
      border-radius: 50%;
    }
  }
}
