.wrapper {
  display: flex;
  align-items: flex-start;

  .sidebar {
    width: 100%;
    min-width: 220px;
    max-width: 220px;
  }

  .content {
    width: calc(100% - 220px);
  }
}
