@import '~@kontentino/ui/dist/scss/colors';

.attachmentsCarousel {
  width: 100%;
  height: 100%;
  position: relative;

  .carouselItem {
    position: relative;
    cursor: default;

    .carouselItemLinkInfo {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    .carouselItemImage {
      height: auto;
      width: 100%;
      background-position: center;
      background-size: cover;
    }

    .carouselItemVideo {
      object-fit: cover;
    }
  }

  .arrow {
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    transition: opacity 199ms cubic-bezier(0, 0, 0.21, 1.11);
    margin: 8px;

    &:hover {
      opacity: 1;
    }

    svg {
      color: $gray80;
    }
  }

  .pagingDots {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    &.pagingDotsWithLinks {
      bottom: 80px;
    }

    .dot {
      cursor: pointer;

      + .dot {
        margin-left: 4px;
      }

      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: $white;
      opacity: 0.4;

      &.active {
        opacity: 1;
      }
    }
  }
}
