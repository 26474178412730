.pageRow {
  display: flex;
  align-items: center;
  border-top: 1px solid #ebecf0;
  font-size: 14px;
  color: #172b4d;

  .pageInfo {
    display: flex;
    align-items: center;
    width: 25%;

    .pageImage {
      height: 32px;
      min-width: 32px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 8px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .pageName {
      @apply tw-truncate;
    }
  }

  .metricsInfo {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      display: inline-block;
      text-align: center;
      width: calc(100% / 5);
      padding: 18px 0;

      &.min {
        color: #de1c22;
        background-color: #f9d3d4;
      }

      &.max {
        color: #36b37e;
        background-color: #d6f3e2;
      }
    }
  }

  .reactions {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      width: calc(100% / 6);
      text-align: center;
    }
  }
}
