@import '~@kontentino/ui/dist/scss/colors';

.filterBar {
  padding: 0 16px 0;
  display: flex;
  align-items: center;

  & > div:not(:last-of-type),
  & > button:not(:last-of-type),
  & > button:first-of-type {
    margin-right: 16px;
  }
}

.sortButton,
.filtersButton {
  font-weight: normal !important;
  font-size: 14px !important;
  background-color: $gray40 !important;

  &:hover {
    svg {
      color: $white !important;
    }

    color: $white !important;
    background-color: $gray80 !important;
  }
}
