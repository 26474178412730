.tableHeadMain {
  tr {
    th {
      padding: 20px;
      text-align: center;
      font-size: 28px;
    }
  }
}

.tableHeadSecondary {
  tr {
    th {
      font-size: 20px;
      padding: 24px;
      padding-bottom: 0;
    }
  }
}
