.item {
  outline: none;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 4px;
  min-width: 90px;
  transition: background-color 199ms cubic-bezier(0, 0, 0.21, 1.11);
  @apply tw-text-grayscale-180;

  &:hover {
    @apply tw-bg-primary-100 tw-text-white;
  }

  & + .item {
    margin-top: 3px;
  }
}

.active {
  @apply tw-bg-primary-120 tw-text-white;
}
