.userActions {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;

  .title {
    display: flex;
    align-items: center;

    .userName {
      padding-left: 18px;
    }
  }

  .dropDownButton.dropDownButton {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
    border-radius: 0;

    &::before {
      position: absolute;
      display: block;
      content: '';
      height: 100%;
      width: 2px;
      border-radius: 10px;
      right: 0;
      transition: 0.1s ease-in-out;
      opacity: 0;
      visibility: hidden;
      @apply tw-bg-primary-100;
    }

    &:hover {
      @apply tw-bg-grayscale-5 tw-text-primary-100;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .menu {
    position: absolute;
    width: max-content;
    top: unset;
    bottom: calc(100% + 10px);
    left: 0;
  }

  .logoutBtn {
    @apply tw-text-danger-100;

    svg {
      @apply tw-text-danger-100;
    }
  }
}
