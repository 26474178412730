.datePicker {
  @apply tw-flex tw-overflow-hidden tw-rounded-md tw-border tw-border-grayscale-20 tw-shadow-xl;

  div[class='react-datepicker'] {
    * {
      font-family: Inter, apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    }

    @apply tw-flex tw-min-w-max tw-flex-col tw-rounded-none tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-grayscale-20 tw-p-0;

    div[class='react-datepicker__children-container'] {
      @apply tw-m-0 tw-w-full;
      padding: 0 !important;
    }

    div[class='react-datepicker__month-container'] {
      @apply tw-flex tw-flex-col tw-gap-y-4 tw-p-3;

      div[class='react-datepicker__header react-datepicker__header--custom'] {
        @apply tw-border-0 tw-bg-white tw-p-0;

        div[class='react-datepicker__day-names'] {
          @apply tw-flex tw-justify-between tw-gap-x-1 tw-pt-3;

          div[class='react-datepicker__day-name'] {
            @apply tw-m-0 tw-font-regular tw-text-grayscale-100;
          }
        }
      }

      div[class='react-datepicker__month'] {
        @apply tw-m-0 tw-flex tw-flex-col tw-gap-y-2;

        div[class*='react-datepicker__week'] {
          @apply tw-flex tw-gap-x-2;

          div[class*='react-datepicker__day'] {
            @apply tw-z-10 tw-m-0 tw-flex tw-h-8 tw-w-8 tw-rounded-md tw-p-0;

            span {
              @apply tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center;
            }
          }

          div[class*='react-datepicker__day--keyboard-selected'] {
            @apply tw-bg-inherit tw-text-inherit;
          }

          div[class*='react-datepicker__day--outside-month'] {
            @apply tw-pointer-events-none tw-invisible tw-text-grayscale-100;
          }

          div[class*='react-datepicker__day--in-range'],
          div[class*='react-datepicker__day--in-selecting-range'] {
            @apply tw-text-grayscale-180;
          }

          div[class*='react-datepicker__day--selected'] {
            span {
              @apply tw-bg-primary-100 tw-font-regular tw-text-white;
            }
          }
          div[class*='react-datepicker__day--in-range'],
          div[class*='react-datepicker__day--in-selecting-range'] {
            @apply tw-relative tw-bg-grayscale-10;

            &:after {
              content: '';
              @apply tw-absolute -tw-right-3 -tw-z-10 tw-h-full tw-w-6 tw-bg-grayscale-10;
            }

            &:last-child {
              &::after {
                content: none;
              }
            }
            &:has(+ div[class*='react-datepicker__day--outside-month']) {
              &::after {
                content: none;
              }
            }
          }
          div[class*='react-datepicker__day--range-start'],
          div[class*='react-datepicker__day--range-end'],
          div[class*='react-datepicker__day--selecting-range-start'],
          div[class*='react-datepicker__day--selecting-range-end'] {
            @apply tw-z-30;

            span {
              @apply tw-rounded-md tw-bg-primary-100 tw-font-regular tw-text-white;
            }
          }

          div[class*='react-datepicker__day--range-start'],
          div[class*='react-datepicker__day--selecting-range-start'] {
            &::before {
              content: none !important;
            }
          }

          div[class*='react-datepicker__day--range-end'],
          div[class*='react-datepicker__day--selecting-range-end'] {
            &::after {
              content: none !important;
            }
          }

          div[class*='react-datepicker__day--today'] {
            @apply tw-font-regular tw-text-primary-100;
          }
        }
      }

      div[class='react-datepicker__month react-datepicker__monthPicker'] {
        @apply tw-m-0 tw-flex tw-flex-col tw-gap-y-1;

        div[class='react-datepicker__month-wrapper'] {
          @apply tw-flex tw-gap-x-1;

          div[class*='react-datepicker__month-text--in-range'] {
            @apply tw-bg-transparent;
          }

          div[class*='react-datepicker__month-text react-datepicker__month-'] {
            @apply tw-m-0 tw-flex tw-h-8 tw-w-20 tw-items-center tw-justify-center tw-rounded-md tw-py-[6px] tw-text-grayscale-180;

            &:hover {
              @apply tw-bg-grayscale-10;
            }

            &[class*='react-datepicker__month-text--today'] {
              @apply tw-bg-inherit tw-font-regular tw-text-primary-100;
            }

            &[class*='react-datepicker__month-text--keyboard-selected'] {
              @apply tw-bg-inherit tw-font-regular tw-text-inherit;
            }

            &[class*='react-datepicker__month-text--selected'] {
              @apply tw-bg-primary-100 tw-font-regular tw-text-white;
            }

            &[class*='react-datepicker__month-text--disabled'] {
              @apply tw-cursor-not-allowed tw-text-grayscale-50;
            }
          }
        }
      }
    }
  }

  *[class*='react-datepicker__triangle'] {
    display: none;
  }
}
