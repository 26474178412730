.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  user-select: none;
  @apply tw-border-b tw-border-solid tw-border-grayscale-10 tw-text-grayscale-180;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
}
