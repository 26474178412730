.DayPicker {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #181b1f !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.CalendarDay__today {
  @apply tw-text-primary-100;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end,
.CalendarDay__selected,
.CalendarDay__selected:hover {
  @apply tw-rounded-sm tw-border-none tw-bg-primary-100 tw-text-white;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active {
  color: white !important;
  @apply tw-bg-primary-100;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span {
  color: #181b1f !important;
  @apply tw-border-none  tw-bg-primary-10;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:hover {
  @apply tw-cursor-not-allowed tw-bg-white tw-text-grayscale-70;
}

.CalendarDay {
  box-sizing: border-box;
}

.CalendarDay,
.CalendarDay:hover {
  border: none;
}

.CalendarDay:hover {
  border-radius: 4px;
}

.DayPickerNavigation_button {
  padding: 4px;
  border-radius: 4px;
}

.CalendarMonth_caption {
  font-weight: 300;
  @apply tw-text-base tw-text-grayscale-180;
}

.DayPicker_weekHeader_li {
  @apply tw-text-grayscale-70;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 27px;
}
