.initializingSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-top: 12px;
  }
}
