body {
  height: 100vh;
  color: #181b1f; // TODO
  background-color: #f8f9fa; // TODO

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
}
