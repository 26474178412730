@import '~@kontentino/ui/dist/scss/colors';

@mixin styled-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    transition: background-color 199ms cubic-bezier(0, 0, 0.21, 1.11);
    background-color: transparentize($gray80, 0.9);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: transparentize($gray80, 0.8);
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 199ms cubic-bezier(0, 0, 0.21, 1.11);
    border-radius: 4px;
    background-color: transparentize($gray80, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background-color: $gray80;
  }
}
