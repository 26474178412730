.progress {
  display: inline-flex;
  vertical-align: bottom;
  flex-shrink: 0;
  transform: scale(1.6);

  circle {
    stroke: currentColor;
    stroke-width: 14px;
    stroke-dasharray: 0;
    fill: none;
  }

  .meter {
    stroke-width: 14px;
    stroke: currentColor;
    fill: none;
    transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transform-origin: center center;
    transform: rotate(-90deg) scaleX(-1);
  }
}
