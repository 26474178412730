@import '~@kontentino/ui/dist/scss/colors';
@import '~@kontentino/ui/dist/scss/shadows';

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.card {
  padding: 16px 24px;
  border-radius: 8px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover,
  &.highlighted {
    &:not(.disabled) {
      .icon {
        background-color: $white !important;
      }
    }
  }

  &:hover:not(.disabled) {
    background-color: $primaryLight90;

    .hint.hint {
      display: inline;
    }
  }

  &.highlighted:not(.disabled) {
    background-color: $primary;

    .textSide,
    .textSide .title {
      color: $white;
    }
  }

  &.disabled {
    cursor: default;

    .textSide,
    .textSide .title {
      color: $gray60;
    }
  }

  .textSide {
    display: flex;
    flex-direction: column;
    min-width: 0;

    .hint {
      margin-right: 4px;
      display: none;
    }

    .title {
      display: inline-block;
      font-size: 14px;
      line-height: 16px;
      color: $gray80;
      margin-bottom: 4px;
      @include truncate;
    }

    .value {
      display: inline-block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      @include truncate;
    }
  }
}
