.sectionLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  header {
    flex-shrink: 0;
  }

  main {
    padding-top: 8px;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}
