@import '~@kontentino/ui/dist/scss/colors';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $dangerLight90;
    margin-bottom: 30px;

    svg {
      color: $dangerLight50;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 18px;
    }

    .smaller {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
