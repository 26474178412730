@import '~@kontentino/ui/dist/scss/colors';
@import '~@kontentino/ui/dist/scss/shadows';
@import '~react-responsive-modal/styles.css';

.react-responsive-modal-overlay,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}

.react-responsive-modal-overlay {
  @apply tw-bg-grayscale-180 tw-bg-opacity-10;
}

[data-navigation-expanded='true'] .react-responsive-modal-root {
  left: 240px;
}

.react-responsive-modal-root {
  left: 64px;
}

.react-responsive-modal-modal {
  @apply tw-m-3 tw-overflow-visible tw-rounded-lg tw-bg-white tw-p-0 tw-shadow-xl;
}
