.header {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  .title {
    font-weight: 600;
    font-size: 20px;
    display: inline-block;
  }

  .actions {
    display: flex;
    align-items: center;

    .toggler {
      margin-left: 16px;
      transition: 0.15s ease-in-out;
      @apply tw-bg-grayscale-70;

      &.inverted {
        transform: rotate(180deg);
      }
    }
  }
}
