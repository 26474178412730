.wrapper {
  width: 540px;
  border-radius: 8px;
  box-shadow: 0px 7px 20px rgba(62, 80, 115, 0.16);
  overflow: hidden;
  @apply tw-bg-white;

  .header {
    height: 190px;
    background: #d7fdf5 url('./welcomeinadsplanner.svg') no-repeat center;
  }

  .body {
    padding: 32px;

    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .description {
      @apply tw-text-sm tw-text-grayscale-70;
    }

    .list {
      margin: 32px 0;

      .listOption {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          font-size: 12px;
          margin-left: 12px;
        }

        & + .listOption {
          margin-top: 8px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
