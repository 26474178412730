@import '~@kontentino/ui/dist/scss/colors';

.searchWrapper {
  display: block;
  position: relative;

  input {
    border-radius: 0;
    border-top-color: transparent;
    border-bottom-color: $gray20;
    border-left: none;
    border-right: none;
    height: 52px;

    &:focus {
      border-top-color: transparent;
      border-bottom-color: $gray20;
    }
  }

  .clearButton {
    text-decoration-line: underline;
    position: absolute;
    top: 18px;
    right: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    color: $gray80;

    &:hover {
      color: $primary;
    }
  }
}
